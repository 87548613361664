<template>
 <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">General Studies</span>/<span style="padding:5px; color:#555">List GS books</span>
        </div>
    </div>


    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search GS books" single-line hide-details></v-text-field>
            </div>
        </div>
        <v-data-table :headers="headers" :items="desserts" :search="search" sort-by="calories" class="elevation-1">
            <template v-slot:item.sn="{ item }">
            {{desserts.indexOf(item) + 1}}
        </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <!-- <v-toolbar-title>List Program</v-toolbar-title> -->
                    <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                    <v-spacer></v-spacer>
                    <v-row>
                        <v-spacer></v-spacer>

                        <!-- <v-col cols="10" sm="10" md="10">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details class="mr-10"></v-text-field>
                        </v-col> -->
                        <v-col cols="4" sm="4" md="4">
                            <v-dialog v-model="dialog" persistent transition="" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on" class="createInsideTableButton">
                                       <v-icon size="30" color="success">mdi-plus-circle</v-icon> Add Book
                                    </button>
                            </template>
                            <v-card>
                                <v-card-title class="text-h7" style="color:#666">
                    Add Book
                    <v-spacer></v-spacer>
                    <span style="color: red; cursor: pointer; font-size: 25px" @click="dialog = false"><v-icon>mdi-close-circle</v-icon></span>

                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>

                                                <v-text-field v-model="bookName" label="Book Name" outlined dense></v-text-field>
                                                <v-text-field v-model="description" label="Book Description"></v-text-field>
                                                </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions style="width: 60%">
                                    <v-spacer></v-spacer>
                                    
                                    <button class="next_button" @click="createBook">
                                        Add Book
                                    </button>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <!-- <template v-slot:[`item.book`]="{ item }">
            <tr>
                <td class="">
                    <button @click="itemInventory(item)">
                        {{ item.book }}
                    </button>
                </td>
            </tr>
        </template> -->
        <template v-slot:item.actions="{ item }">
           <v-tooltip top color="default">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" @click="viewStudents(item)" v-bind="attrs" v-on="on">
                            mdi-eye
                          </v-icon>
                        </template>
                        <span>View students with this {{item.name}}</span>

                      </v-tooltip>
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:item.status="{ item }">
            <div style ="cursor: pointer;"  @click="pushtoStu(item)" >
                View/Add Batch
            </div>
        </template>
    </v-data-table>
    </v-col>
    </v-row>
    
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    data: () => ({
        idnumber: '',
surname: '',
first: '',
profilePic: '',
othername: '',
studentDetails: false,
faculty: '',
department: '',
description: '',
        search: '',
        dialog: false,
        bookName: '',
        assignBookDialog: false,
        bookAssign: '',
bookBatch: '',
regnumber: '',
item: null,
        dialogDelete: false,
        bookDialog: false,
        batchNo: [2, 3, 4, 10],
        batchSum: '',
        headers: [{
                text: 'S/N',
                align: 'start',
                sortable: true,
                value: 'sn',
            },
            {
                text: 'Name',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Date Added',
                value: 'dateadded'
            },
            {
                text: 'Added By',
                value: 'addedby'
            },
            {
                text: 'Total Added',
                value: 'totaladded'
            },
            {
                text: 'Total left',
                value: 'totalleft'
            },
            {
                text: 'Total Batch',
                value: 'totalbatch'
            },
            {
                text: 'Batch Info',
                value: 'status'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
        ],
        select: null,
        items: [],
        desserts: [],
        editedIndex: -1,
        isBookAssigned: null,
    }),

    mounted() {
        this.batchSum = 0;
        for (let i = 0; i < this.batchNo.length; i++) {
            this.batchSum += this.batchNo[i];
        }
        console.log(this.batchSum);
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize();
    },

    methods: {
        closeAssign(){
            this.assignBookDialog = false;
                     this.idnumber = '';
this.surname =''
this.profilePic = '';
this.first = '';
this.othername = '';
this.faculty ='';
this.department ='';
this.regnumber = '';
this.bookBatch = '';
                    this.studentDetails = false;
        },
       
        viewStudents(item){
            const data =  window.btoa(JSON.stringify(item))
            this.$router.push(`/books-students/${data}`);
        },

        async initialize() {
            
          try {
            const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+'get-books';
                const res = await axios.get(url);
                console.log('BOOKS', res);
                if(res.data.code === 's200'){

                    res.data.message.forEach(book => {
                        this.desserts.push({
                            name: book.name,
                        addedby: book.createdBy,
                        dateadded: book.dateCreated.split('T')[0],
                        descip: book.description,
                        totaladded: book.totalNumber,
                        totalleft: book.numberLeft,
                        id: book._id,
                        totalbatch: book?.batchNumber
                        })
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },

        async createBook() {
            try {
                if (!this.bookName) {
                    return Swal.fire({
                        icon: 'info',
                        text: 'Please make sure you fill in all fields',
                        title: 'Oops...'
                    })
                }

                const data = {
                    name: this.bookName,
                    description: this.description,
                    createdBy: 'skolarsuite'
                };
                const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+'create-book'
                const res = await axios.post(url, data);
                if (res.data.code === 's200') {
                    let book = res.data.message;
                    this.desserts.push({
                        name: book.name,
                        addedby: book.createdBy,
                        dateadded: book.dateCreated.split('T')[0],
                        descip: book.description,
                        totaladded: book.totalNumber,
                        totalleft: book.numberLeft,
                        id: book._id,
                        totalbatch: 0
                    });
                    this.dialog = false;
                    this.bookName = '';
                    this.bookDescription = '';
                    Swal.fire({
                        icon: 'success',
                        text: 'Book successfully created',
                        title: 'Successful'
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }
            } catch (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong creating book',
                    title: 'Oops..'
                })
            }
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true;

        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        pushtoStu(item) {
            // localStorage.setItem('bookBatch', JSON.stringify(item))
           const data =  window.btoa(JSON.stringify(item))
           console.log(data);
            this.$router.push(`/gs/bookinventory/${data}`);
        },

        itemInventory(item) {

            this.$router.push({
                path: `/bookInventory`,
                query: {
                    item
                }
            });
        },

        issueBook() {
            this.assignBookDialog = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>



