<template>
    <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">General Studies</span>/<span style="padding:5px; color:#555">List Batch of {{bookInfo.name}}</span>
        </div>
    </div>


    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search GS books" single-line hide-details></v-text-field>
            </div>
        </div>
        <v-data-table :headers="headers" :items="desserts" :search="search" sort-by="calories" class="elevation-1">
        <template v-slot:item.sn="{item}">
            {{desserts.indexOf(item) + 1}}
        </template>
        <template v-slot:top>
            <v-toolbar flat>
                <!-- <v-toolbar-title>List Program</v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                    <v-row>
                        <v-spacer></v-spacer>

                    <v-col cols="3" sm="3" md="3">
                        <v-dialog v-model="dialog"  max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                    <button v-bind="attrs" v-on="on" class="createInsideTableButton">
                                       <v-icon size="30" color="success">mdi-plus-circle</v-icon>  Create Batch
                                    </button>
                            </template>

                            <v-card>
                                <v-card-title class="text-h7" style="color:#666">
                    Add Batch to {{bookInfo.name}}
                    <v-spacer></v-spacer>
                    <span style="color: red; cursor: pointer; font-size: 25px" @click="close"><v-icon>mdi-close-circle</v-icon></span>

                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <!-- <v-col cols="12" sm="12" md="12">
                                                <v-select v-model="batch" :items="items" label="Book Name"></v-select>
                                            </v-col> -->
                                            <v-col cols="11" sm="11" md="11" class="ma-auto">
                                                <v-text-field v-model="number" type="number" label="Quantity of books in number" outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions style="width: 60%">
                                    <v-spacer></v-spacer>
                                    
                                    <button class="next_button" @click="createBatch">
                                        Add Batch
                                    </button>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.book`]="{ item }">
            <tr>
                <td class="">
                    <button @click="itemInventory(item)">
                        {{ item.book }}
                    </button>
                </td>
            </tr>
        </template>
        <!-- <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template> -->
        <template v-slot:item.stulist="{ item }">
            <div style="cursor: pointer" @click="viewIssued(item)">
                View Student
            </div>
        </template>
    </v-data-table>
    </v-col>
    </v-row>

</div>
</div>

</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    data: () => ({
        search: '',
        BookName: '',
        assignBookDialog: false,
        showStudentDetail: false,
        viewListofStudent: false,
        dialog: false,
        dialogDelete: false,
        bookDialog: false,
        items: [],
        number: '',
        batch: '',
        bookInfo: null,
    
        headers: [{
                text: 'S/N',
                align: 'start',
                sortable: true,
                value: 'sn',
            },
            {
                text: 'Batch No',
                value: 'batchno'
            },
            {
                text: 'Date Added',
                value: 'dateadded'
            },
            {
                text: 'Added By',
                value: 'addedby'
            },
            {
                text: 'Total Added',
                value: 'totaladded'
            },
            {
                text: 'Total left',
                value: 'totalleft'
            },
            {
                text: 'View Student',
                value: 'stulist'
            },
            // {
            //     text: 'Actions',
            //     value: 'actions',
            //     sortable: false
            // },
        ],
        desserts: [],
        editedIndex: -1,
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },

    mounted() {
        // console.log('Params: ', this.$route.query)
        // this.BookName = this.$route.query.item.name
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },


    created() {
        const data = window.atob(this.$route.params.id);
        this.bookInfo = JSON.parse(data);
        this.initialize();
        this.fetchBooks();
    },

    methods: {
        async createBatch(){
            try {
                const url =  process.env.VUE_APP_GS_V1_API_ENDPOINT+'create-book-batch'
                const res = await axios.post(url, {bookId: this.bookInfo.id, number: this.number, createdBy: 'skolarsuit'});
                console.log('CREATED', res);
                if(res.data.code === 's200'){
                    const batch = res.data.message;
                    this.desserts.push({
                        name: batch.bookId.name,
batchno: batch.series,
dateadded: batch.dateCreated.split('T')[0],
addedby: batch.createdBy,
totaladded: batch?.totalNumber,
totalleft: batch.totalLeft
                    });

                    this.batch = '';
                    this.number = '';
                    this.dialog = false;
                    Swal.fire({
                        text: 'Batch created successfully',
                        title: 'Successful',
                        icon: 'success'
                    });
                }else{
                    Swal.fire({
                        text: res.data.message,
                        title: 'Error',
                        icon: 'error'
                    })
                }
            } catch (error) {
                Swal.fire({
                        text: 'Make sure your internet connection is on',
                        title: 'Error',
                        icon: 'error'
                    })
                console.log(error);
            }
        },
        async fetchBooks() {
            
          try {
            const url =  process.env.VUE_APP_GS_V1_API_ENDPOINT+'get-books'
                const res = await axios.get(url);
                console.log('BOOKS', res);
                if(res.data.code === 's200'){
                    





                    res.data.message.forEach(book => {
                        this.items.push({
                            text: book.name,
                        
                        value: book._id,
                        })
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        async initialize() {
             try {
                const url =  process.env.VUE_APP_GS_V1_API_ENDPOINT+`fetch-book-batch/${this.bookInfo.id}`
                const res = await axios.get(url);
                console.log('BOOKS', res);
                if(res.data.code === 's200'){
                    res.data.message.forEach(batch => {
                        this.desserts.push({
                           name: batch.bookId.name,
batchno: batch.series,
addedby: batch.createdBy,
dateadded: batch.dateCreated.split('T')[0],
totaladded: batch.totalNumber,
totalleft: batch.totalLeft,
id: batch._id
                        })
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        issueBook() {
            this.assignBookDialog = true
        },
        viewIssued(item) {
            // JSON.parse(this.$route.params.batch)
             const book = JSON.parse(window.atob(this.$route.params.book));
            const data = window.btoa(JSON.stringify({book, batch: item}));            
            this.$router.push(`/gs/student-with-books/${data}`);
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        showDetail() {
            this.showStudentDetail = true
        },
        itemInventory(item) {
            localStorage.setItem('lskdjsldkjsl', JSON.stringify(item))
            this.$router.push({
                path: `/bookInventory`,
                query: {
                    item
                }
            });
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.number = ''
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.next_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 40px;
    background: #006794;
    border-radius: 3px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.btn-close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 40px;
    background: #DFDFDF;
    border-radius: 3px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    color: #828282;
}
</style>
