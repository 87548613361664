<template>
<v-card class="ma-4 elevation-10">
    <v-toolbar class="mb-2" color="#006794" dark flat>
        <v-toolbar-title>Students with {{bookInfo.book.name}} Batch {{bookInfo.batch.batchno}}</v-toolbar-title>
    </v-toolbar>
   <div>
         <!-- <div>
           <button style="padding: .9rem; background: #ccc" @click="$router.go(-1)">
        <img src="../../../assets/arrowleft.svg" alt="" srcset="" />
      </button>
       </div> -->
        <v-data-table :headers="headers" :items-per-page="6" :items="desserts" :search="search" sort-by="calories" class="elevation-1">
   
    </v-data-table>
   </div>
</v-card>
</template>

<script>
// import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    data: () => ({
        search: '',
        regnumber: '',
        bookInfo: {},
        dialog: false,
        dialogDelete: false,
        items: [1,2,3,4,5],
        headers: [{
                text: 'Student Name',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Reg No',
                value: 'regNo'
            },
            {
                text: 'Department',
                value: 'deptment'
            },
            {
                text: 'Faculty',
                value: 'faculty'
            },
            {
                text: 'Date Collected',
                value: 'datecollected'
            },
        ],
        desserts: [],
        editedIndex: -1,
        
    }),

    mounted() {
        // console.log('Params: ', this.$route.query)
        // this.editedItem.totalbatch = this.$route.query.item.totalbatch
        // console.log('Heeeeeeeeelloo',this.editedItem.totalbatch);
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },

    created() {
        console.log('PARAMS',this.$route.params);

        // this.bookInfo = JSON.parse(window.atob(this.$route.params.id));
        // console.log('BOOKINFO',this.bookInfo)
        this.fetchStudentsWithBooks();
    },

    methods: {
       async fetchStudentsWithBooks(){
        const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+`get-assignbooks/${this.bookInfo.book.id}/${this.bookInfo.batch.id}`
            try {
                const res = await axios.get(url);

                if(res.data.code === 's200'){
                    res.data.message.forEach(el => {
                        this.desserts.push({
                            name: `${el.studentId.surname} ${el.studentId.firstname}`,
regNo: el.studentId.regNumber,
deptment: el.department.name,
faculty: el.faculty.name,
datecollected: el.givenOn.split('T')[0],
                        })
                    });
                }
                
            } catch (error) {
                console.log(error)
            }
        },
   
     },
}
</script>

<style lang="scss" scoped>

</style>
