<template>
<div class="home">
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">General Studies</span>/<span style="padding:5px; color:#555">Registered Course</span>
    </div>
  </div>

  <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
    <v-card class="pt-2" elevation:10>
      
      <v-row class="m-3">
        <v-col class="col-sm-12 col-md-6 col-lg-2">

          <v-text-field dense clearable outlined v-model="regnumber" label="Enter Reg Number"></v-text-field>
        </v-col>
        <!-- <v-col class="col-sm-12 col-md-6 col-lg-2">
          <v-select dense clearable outlined v-model="program" :items="programs" label="Select Program"></v-select>
        </v-col> -->
        <v-col class="col-sm-12 col-md-6 col-lg-2">
          <v-autocomplete dense clearable outlined v-model="session" :items="sessions" label="Select Session"></v-autocomplete>
        </v-col>
        <v-col class="col-sm-12 col-md-6 col-lg-2">

          <v-autocomplete dense clearable outlined v-model="course" :items="courses" label="Select Course"></v-autocomplete>
        </v-col>
        <!-- <v-col class="col-sm-12 col-md-6 col-lg-2">

          <v-autocomplete dense clearable outlined v-model="faculty" :items="faculties" label="Select Faculty"></v-autocomplete>
        </v-col> -->

        <v-col class="col-sm-12 col-md-6 col-lg-2">

          <v-autocomplete dense clearable outlined v-model="department" :items="departments" label="Select Department"></v-autocomplete>
        </v-col>

        <v-col class="col-sm-12 col-md-6 col-lg-2" style="margin-top:0 !mportant">

          <v-autocomplete dense clearable outlined v-model="level" :items="levels" label="Select Level"></v-autocomplete>
        </v-col>

        <v-col class="col-sm-12 col-md-6 col-lg-2" style="margin-top:0 !mportant">
            <v-btn color="#006794" style="color: #fff" @click="submit" v-if="!activities" class="col-md-8"> Search </v-btn>
            <v-btn color="#c0e7fe"   class="col-md-8" style="margin:auto" disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Searching</v-btn>
  </v-col>
      </v-row>
       
    </v-card>
     

      <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
          <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Table" single-line hide-details></v-text-field>
            </div>
          </div>
          <v-data-table :headers="headers"
      :items="data"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
      @page-count="pageCount = $event" :search="search">

      <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{data.map(function(x) {return x.reg; }).indexOf(item.reg)+1}}</td>
                        </tr>
                    </template>


            <template v-slot:item.actions="{item}">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="reset(item.el)">
                    <v-list-item-title>
                      <v-icon small class="mr-2">
                        mdi-clipboard-arrow-left
                      </v-icon>Reset Course
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openRegisteredCourses(item.el)">
                    <v-list-item-title>
                      <!-- <img style="height: 20px; width: 20px" src="../../../assets/icons8-external-link-48.png" alt=""> -->
                      See Registered Courses</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <!-- <template v-slot:bottom>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
       
      </div>
    </template>
     -->
     
          </v-data-table>
          
          <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
      <v-spacer></v-spacer>
      <!-- <v-text-field
        :value="itemsPerPage"
        label="Items per page"
        type="number"
        min="-1"
        max="15"
        @input="itemsPerPage = parseInt($event, 10)"
      ></v-text-field> -->
      </div>

        </v-col>
      </v-row>
      <div>
      </div>
  </div>

  <!-- Modals -->
  <v-dialog v-model="dialogRegset" width="400">
    <v-card>
      <v-card-title style="color:#666; font-size:16px">
        Reset course registration
        <v-spacer></v-spacer>
        <v-icon @click="dialogRegset=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>
        <v-row>

          <div class="col-md-12 pa-8 text-center">
            <!-- <img style="height: 150px; width: 150px" src="../../../assets/caution.gif" alt=""> <br> -->
            <v-icon class="m-auto" color="error" > mdi-48px mdi-sign-caution</v-icon><br>
            Are you sure you want to reset general stuies course registration for <strong>{{courseInfo?.studentId?.surname}} {{courseInfo?.studentId?.firstname}} {{courseInfo?.studentId?.othernames}}</strong>. What this means is that this student will have to re-submit the course registration to be seen as to have registered for general studies.
          </div>

        </v-row>
        <v-card-actions class="m-auto">
          <v-btn color="#c0e7fe" @click="resetCourse" class="col-md-5 ma-auto">Proceed</v-btn>
        </v-card-actions>
      </v-card-text>

    </v-card>
  </v-dialog>


  <v-dialog v-model="dialogCourse" max-width="550">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
       Registered Course
        <v-spacer></v-spacer>
        <v-icon @click="dialogCourse=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>
        <v-row>


          <div class="col-md-12 pa-7 pb-0">
            <h6>Bio Information</h6>
           <div class=" d-flex justify-start align-center">
            <img style="height: 150px; overflow: hidden; width: 150px; border-radius: 50%; border: 1px solid gray" :src="courseInfo?.studentId?.profileUrl" alt="">
            <div class="ml-7">
              <p><strong>Surname: </strong>{{courseInfo.studentId.surname}}</p>
              <p><strong>First Name: </strong>{{courseInfo.studentId.firstname}}</p>
              <p><strong>Othernames: </strong>{{courseInfo.studentId.othernames}}</p>
              <!-- <p><strong>Program:  </strong>{{courseInfo.program.program}}</p> -->
              <!-- <p><strong>Faculty: </strong>{{courseInfo.facultyId.name}}</p> -->
              <p><strong>Department: </strong>{{courseInfo.departmentId.name}}</p>
           </div>

            </div>
          </div>

          <!-- <div class="col-md-12">
            <v-divider style="margin-top:0px"></v-divider>
          </div> -->
          
          <div class="col-md-12 pa-7">
            <h6>Registered Courses</h6>
          <div class="col-md-12">
            <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in courseInfo.courses"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon>mdi-decagram-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <span>{{item.course_code}}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
          </div>
          </div>


        </v-row>
 
      </v-card-text>

    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {allFaculties,allSession,allDepartments} from '@Appmart/utility'

import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      search: '',
      dialogCourse: false,
      show: false,
      page: 1,
        itemsPerPage: 100,

      levels: [{
        text: '100',
        value: 1
      }, {
        text: '200',
        value: 2
      }, {
        text: '300',
        value: 3
      }, {
        text: '400',
        value: 4
      }, {
        text: '500',
        value: 5
      }, {
        text: '600',
        value: 6
      }],
      dialogRegset: false,
      courses: [],
      sessions: [ {text: 2021,
        value: 2021},{text: 2022,
        value: 2022},{text: 2023,
        value: 2023}],
      faculties: [],
      departments: [],
      regnumber: '',
      program: '',
      programs: [],
      session: '',
      level: '',
      department: '',
      faculty: '',
      course: '',
      data: [],
      activities:false,
      courseInfo: {studentId: {}, facultyId:{}, departmentId: {}, program: {}},
      headers: [{
          text: 'S/N',
          value: 'sn'
        },{
          text: 'Reg Number',
          value: 'reg'
        },
        {
          text: 'Fullname',
          value: 'fullname'
        },
        // {
        //   text: 'Program',
        //   value: 'program'
        // },
        {
          text: 'Department',
          value: 'department'
        },
        // {
        //   text: 'Session',
        //   value: 'session',
        // },
        {
          text: 'Level',
          value: 'level'
        },
        {
          text: 'Date Registered',
          value: 'date',
        },
        {
          text: 'Actions',
          value: 'actions'
        }

      ]
    }
  },
  beforeMount() {
    this.fetchFaculties();
    this.fetchDepartments();
    this.fetchCourses();
    // this.fetchProgram();
    this.fetchSession();
  },

  computed: {
      pageCount () {
        return Math.ceil(this.data.length / this.itemsPerPage)
      },
    },

  mounted(){
    this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;

  },
  methods: {
    openRegisteredCourses(item) {
      console.log(item);
      this.courseInfo = item;
      this.dialogCourse = true;
    },
   async resetCourse(){
    try {
      const url =  process.env.VUE_APP_GS_V1_API_ENDPOINT+'reset-gs-course'
     const res = await axios.post(url, {studentId: this.courseInfo.studentId._id, program: this.program, session: this.session, resettedBy: 'Skolarsuit'});

     if(res.data.code == 's200'){
       this.dialogRegset = false;
       this.data = this.data.filter(el => el.id !== this.courseInfo.studentId._id);

       Swal.fire({icon: 'success', text: `You have successfully reset ${this.courseInfo.studentId.surname} ${this.courseInfo.studentId.firstname}`})
      
     }else{
      Swal.fire({icon: 'error', text: res.data.message, title: 'Oops...'})
     }
    } catch (error) {
      console.log(error)
    }
    },
    reset(item) {
      this.courseInfo = item;
      this.dialogRegset = true;
      console.log(item);
    },
    close() {
      this.course = '';
      this.regnumber = '',
        this.department = '';
      this.faculty = '';
      this.show = false;
      this.data = [];
      this.session = '';
      this.level = '';
    },
    
    async submit() {
      this.activities = true
      this.data=[]
      if (!this.session) {
        this.activities = false

        return Swal.fire({
          icon: 'info',
          text: 'Please select session',
          title: 'Oops..'
        })
      }
      if (!this.program) {
        this.activities = false

        return Swal.fire({
          icon: 'info',
          text: 'Please select program',
          title: 'Oops..'
        })
      }
    
      try {
        const data = {
          courseId: this.course ? this.course.id : 'undefined',
          departmentId: this.department ? this.department : 'undefined',
          facultyId: this.faculty.id ? this.faculty.id : 'undefined',
          level: this.level ? this.level : 'undefined',
          session: this.session ? this.session : 'undefined',
          program: this.program,
          regNumber: this.regnumber ? this.regnumber : 'undefined'
        };
        const url =  process.env.VUE_APP_GS_V1_API_ENDPOINT+'fetch-registered-gs-courses'
        const res = await axios.post(url, data);
        console.log(res);

        if (res.data.code == 's200') {
          const data = []
          if(res.data.message.length <= 0){
            this.activities = false
            return Swal.fire({
          icon: 'info',
          text: 'No record found for the filters selected',
          title: 'Oops..'
        })
          }
          res.data.message.forEach(el => {
            data.push({
              reg: el.level=='1'?el.studentId.admissionNumber:el.studentId.regNumber,
              date:el.dateRegistered,
              fullname: `${el.studentId.surname} ${el.studentId.firstname}`,
              // program: el.program.program,
              // faculty: el.facultyId.name,
              department: el.departmentId.name,
              // session: el.session,
              level: el.level + '00',
              semester: el.semester,
              id: el.studentId._id,
              el,
              data: JSON.stringify(el)
            })
          });

          this.data = data;
          this.show = true;
          this.activities = false

        }
      } catch (error) {
        console.log(error);
      }
    },
    // async fetchProgram() {
    //   try {
    //     const res = await axios.get('http://localhost:5000/fetchprograms');
    //     console.log('RESET', res)
    //     if (res.data.code == '00') {
    //       res.data.data.forEach(program => {
    //         this.programs.push({
    //           text: program.program,
    //           value: program.slug
    //         })
    //       });
    //     } else {
    //       console.log("ERROR")
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    async fetchSession() {
      try {
        
        const res = await allSession().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
        if (res.data.code === 's200') {
          res.data.message.forEach(session => {
            this.sessions.push({
              value: session.academicYear,
              text: session.academicYear,
            })
          })

        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFaculties() {
      try {
        const res = await allFaculties().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
if (res.data.code == '00') {

this.faculties = res.data.message.map((el) => {
  return {
    text: el.name,
    value: {
      name: el.name,
      id: el._id
    }
  }
});
// console.log(datas);

}
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDepartments() {
      try {
        this.department = '';
        
        const res = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
        // console.log('Department', res);
        if (res.data.code === '00') {
          const departments = res.data.message.map(el => {
            if(el.facultyId == this.faculty.id){
            return {
              text: el.name,
              value: el._id
            }
          }
          })

          this.departments.push(...departments)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCourses() {
      try {
        const url =  process.env.VUE_APP_GS_V1_API_ENDPOINT+'get-courses'
        const res = await axios.get(url);
        if (res.data.code == 's200') {
          res.data.message.forEach(course => {
            if(course.course_code.includes('GST')){
            this.courses.push({
              text: course.name+' '+'('+course.course_code+')',
              text1: course.course_code,
              value: {
                id: course._id,
                name: course.name
              }
            })
          }
          })
        }
      } catch (error) {
        console.log(error);
      }
    },
  }

}
</script>

<style>

</style>
