<template>
 <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">General Studies</span>/<span style="padding:5px; color:#555">Issue Books</span>
        </div>
    </div>



    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search GS books" single-line hide-details></v-text-field>
            </div>
        </div>



    <v-data-table :headers="headers" :items="desserts" :search="search" sort-by="calories" class="elevation-1 px-10">
        <template v-slot:item.sn="{ item }">
            {{desserts.indexOf(item) + 1}}
        </template>
        <!-- <template v-slot:[`item.book`]="{ item }">
            <tr>
                <td class="">
                    <button @click="itemInventory(item)">
                        {{ item.book }}
                    </button>
                </td>
            </tr>
        </template> -->
        <template v-slot:item.asignbooks="{ item }">
            <div style="cursor: pointer;" @click="fetchBatches(item)">
                Issue book
            </div>
        </template>
    </v-data-table>
    <v-dialog v-model="assignBookDialog" persistent width= "600" max-width="800">
        <v-card> 
            <v-card-title class="text-h7" style="color:#666">
                Issue book to student
                    <v-spacer></v-spacer>
                    <span style="color: red; cursor: pointer; font-size: 25px" @click="assignBookDialog = false"><v-icon>mdi-close-circle</v-icon></span>

                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>
            <v-card-text>
                <v-container v-if="!studentDetails">
                    <v-row>
                        <v-col cols="11" sm="11" md="11" class="ma-auto">
                            <v-text-field v-model="regnumber" label="Student Reg. No" type="text" dense outlined></v-text-field>
                            <v-select v-model="bookBatch" :items="items" label="Batch No"></v-select>

                        </v-col>
                        
                    </v-row>
                    <v-card-actions style="width: 60%">
                        <button @click="studentinfo" class="next_button">
                            Next
                        </button>
                    </v-card-actions>
                </v-container>
                <v-container v-if="studentDetails">
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-avatar size="120" tile style="align:center;">
                                <v-img :src="profilePic"></v-img>
                            </v-avatar>
                        </v-col>
                    </v-row>
                    <v-card class="elevation-1 mt-10 mb-10">
                        <div style="background: #607D8B; padding:10px; color:#fff">Student Bio Data</div>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-container>
                                    <v-row>
                                        <v-col cols="4" sm="4">
                                            <v-text-field v-model="idnumber" label="ID NO" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4">
                                            <v-text-field v-model="surname" label="Surname" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4">
                                            <v-text-field v-model="first" label="Name" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4">
                                            <v-text-field v-model="othername" label="Othername" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4">
                                            <v-text-field v-model="faculty" label="Faculty" readonly></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4">
                                            <v-text-field v-model="department" label="Department" readonly></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card>
                        <h3 v-if="!isBookAssigned">Book has been assign to student already</h3>
                    <v-card-actions style="width: 60%">
                        <v-spacer></v-spacer>
                        <button class="btn-close mr-4" @click="closeAssign">
                            Cancel
                        </button>
                        <button :disabled="!isBookAssigned" @click="assignBook" class="next_button" :style="[ !isBookAssigned ? {'background-color': '#ccc'} : '']">
                            Issue Book
                        </button>
                    </v-card-actions>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
    </v-col>
    </v-row>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { secureAxios } from '@Appmart/utility';

export default {
    data: () => ({
        idnumber: '',
surname: '',
first: '',
profilePic: '',
othername: '',
studentDetails: false,
faculty: '',
department: '',
description: '',
        search: '',
        dialog: false,
        bookName: '',
        assignBookDialog: false,
        bookAssign: '',
bookBatch: '',
regnumber: '',
item: null,
        dialogDelete: false,
        bookDialog: false,
        batchNo: [2, 3, 4, 10],
        batchSum: '',
        headers: [{
                text: 'S/N',
                align: 'start',
                sortable: true,
                value: 'sn',
            },
            {
                text: 'Name',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Date Added',
                value: 'dateadded'
            },
            {
                text: 'Added By',
                value: 'addedby'
            },
            {
                text: 'Total Added',
                value: 'totaladded'
            },
            {
                text: 'Total left',
                value: 'totalleft'
            },
            {
                text: 'Total Batch',
                value: 'totalbatch'
            },
            {
                text: 'Asign Book',
                value: 'asignbooks'
            },
        ],
        select: null,
        items: [],
        desserts: [],
        editedIndex: -1,
        isBookAssigned: null,
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    mounted() {
        this.batchSum = 0;
        for (let i = 0; i < this.batchNo.length; i++) {
            this.batchSum += this.batchNo[i];
        }
        console.log(this.batchSum);
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize();
    },

    methods: {
        closeAssign(){
            this.assignBookDialog = false;
                     this.idnumber = '';
this.surname =''
this.profilePic = '';
this.first = '';
this.othername = '';
this.faculty ='';
this.department ='';
this.regnumber = '';
this.bookBatch = '';
                    this.studentDetails = false;
        },
        async studentinfo(){
            try{
                if(!this.regnumber || !this.bookBatch){
                    return 'error'
                }
                const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+'fetch-student-data'
                const res = await axios.post(url, {regNumber: this.regnumber});
                console.log('Student',res);

                if(res.data.code == 's200'){
                    const data = res.data.message;
                     this.idnumber = data.regNumber;
this.surname =data.surname;
this.profilePic = data.profileUrl;
this.first = data.firstname;
this.othername = data.othernames;
this.faculty =data?.faculty.name;
this.department =data?.department.name;
                    this.studentDetails = true;
                    this.isBookAssigned = !res.data.message.isBookAssigned;
                }else{
                    Swal.fire({icon: 'error', text: res.data.message, title: 'Error'})
                }
            }catch(error){
                console.log(error);
            }
        },
        viewStudents(item){
            const data =  window.btoa(JSON.stringify(item))
            this.$router.push(`/books-students/${data}`);
        },
        async fetchBatches(book){
            this.regnumber = '';
            this.bookBatch =''
            try {
                this.item = book;
                this.assignBookDialog = true;
                console.log('ID', book.id);
                const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+`/fetch-book-batch/${book.id}`;
                const res = await axios.get(url);
                console.log('BOOKsssS', res);
                if(res.data.code === 's200'){
                    const arr = [];
                    res.data.message.forEach(batch => {
                        if(batch.totalLeft > 0){
                            arr.push({
text:`Batch ${batch.series} (${ batch.totalLeft} Book Left)`,
value: batch._id
                        })
                        }

                    });

                    this.items = [];
                    this.items.push(...arr);
                }else{
                    this.items = [];
                }
            } catch (error) {
                console.log(error);
            }
        },

        async assignBook(){
            try {
                if(!this.regnumber){
                    return Swal.fire({icon: 'error', text: 'Please enter students registration number', title: 'Error'});
                } 

                const data = {
                    regNumber: this.regnumber, batchId: this.bookBatch , givenBy: 'skolarsuite'
                }
                const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+'assign-book'
                const res = await axios.post(url, data);
                if(res.data.code === 's200'){
                    this.desserts[this.desserts.indexOf(this.item)].totalleft--;
                    this.bookBatch = null;
                    this.assignBookDialog = false;
                    this.dialog = false;
                    this.regnumber = '';
                    this.studentDetails = false;
                    return Swal.fire({icon: 'success', text: 'Book was assigned to student successfully', title: 'Success'});
                }else{
                    Swal.fire({icon: 'error', text: res.data.message, title: 'Error'})
                }
            } catch (error) {
                console.log(error)
            }
        },

        async initialize() {
            
          try {

            const url = process.env.VUE_APP_GS_V1_API_ENDPOINT+'get-books';
            const res = await secureAxios.get(url);
                // const res = await axios.get(url);
                console.log('BOOKS', res);
                if(res.data.code === 's200'){

                    res.data.message.forEach(book => {
                        this.desserts.push({
                            name: book.name,
                        addedby: book.createdBy,
                        dateadded: book.dateCreated.split('T')[0],
                        descip: book.description,
                        totaladded: book.totalNumber,
                        totalleft: book.numberLeft,
                        id: book._id,
                        totalbatch: book?.batchNumber
                        })
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true;

        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        issueBook() {
            this.assignBookDialog = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.next_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 40px;
    background: #006794;
    border-radius: 3px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.btn-close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 40px;
    background: #DFDFDF;
    border-radius: 3px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    color: #828282;
}
</style>



