<template>
        <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">General studies registration report</span>
        </div>
    </div>

    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
            <!-- <div class="menu_text mb-3 pl-3" style="height: 57px;">Gs Courses</div> -->
            <v-card >
    <v-toolbar class="mb-2" color="#006794" dark flat>
        <v-toolbar-title>Gs Courses</v-toolbar-title>
    </v-toolbar>
    <div class="pa-5">
        <v-row>
              <v-col cols="4">
                <strong class="mb-4 d-block">Selete Session</strong>
                <v-select outlined v-model="session" :items="sessions" label="Select Session"></v-select>
            </v-col>
            <v-col cols="4">
                                <strong class="mb-4 d-block">Selete Course</strong>

                <v-select outlined v-model="course" :items="courses" label="Select Course"></v-select>
            </v-col>
            <v-col cols="4">

                                <!-- <v-autocomplete v-model="faculty" :items="faculties" style="max-width: 100% !important" outlined label="Select Faculty"></v-autocomplete> -->

                <v-select outlined v-model="faculty" :items="faculties" label="Select Faculty" dense></v-select>
            </v-col>
            
            <v-col cols="4">
                                <strong class="mb-4 d-block">Selete Department</strong>

                <v-select outlined v-model="department" :items="departments" label="Select Department"></v-select>
            </v-col>
            <v-col cols="4">
                                <strong class="mb-4 d-block">Enter Reg Number</strong>

                <v-text-field outlined v-model="regnumber" label="Enter Reg Number"></v-text-field>
            </v-col>
                <v-col cols="4">
                                    <strong class="mb-4 d-block">Select Level</strong>

                <v-select outlined v-model="level" :items="levels" label="Select Level"></v-select>
            </v-col>
           
        </v-row>
        <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="3">

                <v-btn color="" block @click="close"  > Clear </v-btn>
            </v-col>
             <v-col cols="3">

                <v-btn color="primary" block @click="submit"  > Search </v-btn>
            </v-col>
            
        </v-row>
        <div v-if="show" class="mt-7">
            <v-data-table :items="data" :headers="headers"></v-data-table>
        </div>
        <div>
        </div>
    </div>
    <div>

    </div>
  </v-card>
            <div class="ma-4">
                <div v-if="hideTable">
                    <v-card elevation="0" class="col-md-12">
                        <div style="background:#607D8B; padding:10px;color:#fff">GS-Courses Profiled</div>
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table :headers="headers" :items="desserts" :sort-desc="[false, true]" multi-sort :search="search" class="elevation-1 col-md-12">
                            <template v-slot:item.sn="{item}">
                                {{desserts.indexOf(item) + 1}}
                            </template>
                            <template v-slot:item.actions="{}">
                                <v-tooltip top color="default">
                                    <template v-slot:activator="{ }">
                                        <v-row justify="left" class="ml-2">
                                            <v-dialog v-model="dialog" width="700px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <!-- <v-icon class="text-right" text="false" >mdi-account</v-icon> -->
                                                    <span style="font-size: 20px" v-bind="attrs" v-on="on"><i class="far fa-eye"></i></span>
                                                </template>
                                                <v-card class="gray">
                                                    <div>
                                                        <div style="background:#607D8B; padding:10px;color:#fff">Student Profile</div>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col cols="12" sm="12">
                                                                    <v-avatar size="120" tile style="align:center;">
                                                                        <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
                                                                    </v-avatar>
                                                                </v-col>
                                                            </v-row>
                                                            <v-card class="elevation-4 mt-10 mb-10">
                                                                <div style="background: #607D8B; padding:10px; color:#fff">Student Bio Data</div>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12">
                                                                        <v-container>
                                                                            <v-row>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field v-model="surname" label="Surname" readonly></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field v-model="first" label="Name" readonly></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field v-model="othername" label="Othername" readonly></v-text-field>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field v-model="faculty" label="Faculty" readonly></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field :disabled="disableFile" v-model="department" label="Department" readonly></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field v-model="course" label="Course" readonly></v-text-field>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col cols="4" sm="4">
                                                                                    <v-text-field v-model="crcprofiled" label="Degree" readonly></v-text-field>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card>
                                                            <div class="coursesProfiled">
                                                                <div style="background:#607D8B; padding:10px;color:#fff">Courses Profiled for This Student</div>
                                                                <v-data-table :headers="crcheaders" hide-default-footer :items="crcdesserts" :items-per-page="3" class="elevation-4 mb-6"></v-data-table>
                                                            </div>
                                                            <div class="btnbtn">
                                                                <button class="approve">Approve</button>
                                                                <button class="mx-6 decline">Declined</button>
                                                                <button class="reset">Reset</button>
                                                            </div>
                                                        </v-container>
                                                    </div>
                                                </v-card>
                                            </v-dialog>
                                        </v-row>
                                    </template>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>
            </div>
            </v-col>
            </v-row>
            </div>
    </div>
    </template>
    
    <script>
        import axios from 'axios';
        import Swal from 'sweetalert2';
        import { allDepartments, allFaculties, allSession} from "@Appmart/utility";

        export default {
            data(){
                return {
                    show: false,
                    levels: [{
                    text: '100',
                    value: 1
                }, {
                    text: '200',
                    value: 2
                }, {
                    text: '300',
                    value: 3
                }, {
                    text: '400',
                    value: 4
                }, {
                    text: '500',
                    value: 5
                }, {
                    text: '600',
                    value: 6
                }],
                    courses: [],
                    sessions: [],
                    faculties: [],
                    departments: [],
                    regnumber: '',
                    session: '',
                    level: '',
                    department: '',
                    faculty: '',
                    course: '',
                    data: [],
                    headers: [
                        {
                            text: 'Reg Number',
                            value: 'reg'
                        },
                        {
                            text: 'Course Name',
                            value: 'course'
                        },
                        {
                            text: 'Fullname',
                            value: 'fullname'
                        },
                        {
                            text:'Program',
                            value: 'program'
                        },
                        {
                            text: 'Faculty',
                            value: 'faculty',
                        },
                        {
                            text: 'Department',
                            value: 'department'
                        },
                        {
                            text: 'Session',
                            value: 'session',
        
                        },
                        {
                            text: 'Level',
                            value: 'level'
                        },
                        {
                            text: 'Semester',
                            value: 'semester'
                        }
                    ]
                }
            },
            beforeMount(){
                this.fetchFaculties();
                this.fetchDepartments();
                this.fetchCourses();
                this.fetchSession();
            },  
            methods: {
                close(){
                    this.program = '';
                    this.course = '';
                    this.regnumber = '',
                    this.department = '';
                    this.faculty = '';
                    this.show = false;
                    this.data = [];
                    this.session = '';
                    this.level = '';
                },  
              async submit(){
                if(!this.course || !this.department || !this.session ){
                    return Swal.fire({icon: 'info', text:'Please make you select Course, Department and Session to proceed', title: 'Oops..'})
                }
                try {
                    const data = {
                        programName: 'undergraduate regular',
                        courseId: this.course.id,
                        departmentId: this.department,
                        facultyId: this.faculty ? this.faculty : 'undefined',
                        level: this.level ? this.levle : 'undefined',
                        session: this.session,
                        registrationNumber: this.regnumber ? this.regnumber : 'undefined'
                    };
                    const res = await axios.post('http://localhost:5000/fetch-registered-course',data);
                    console.log(res);
        
                    if(res.data.code == 's200'){
                        res.data.message.forEach(el => {
                            this.data.push({
                                reg: el.registrationNumber,
        course: this.course.name,
        fullname: `${el.studentId.surname} ${el.studentId.firstname}`,
        program: el.program.program,
        faculty: el.facultyId.name,
        department: el.departmentId.name,
        session: this.session,
        level: el.level,
        semester: el.semester
                            })
                        })
                        this.show = true;
        
                    }
                    
                } catch (error) {
                    console.log(error);
                }
               },
                     async fetchSession() {
                    try {
                        const sessions = await allSession()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });
                        if (sessions.data.code === 's200') {
                            sessions.data.message.forEach(session => {
                                this.sessions.push({
                                    value: session.academicYear,
                                    text: session.academicYear,
                                })
                            })
        
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
                   async fetchFaculties() {
                    try {
        const activeFaculties = await allFaculties()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        if (activeFaculties.data.code == "00") {
          console.log(activeFaculties);
          activeFaculties.data.message.forEach((el) => {
            this.faculties.push({
                text: el.name,
                            value: {
                                id: el._id,
                                fcode: el.code,
                                code: el.code
                            }
            
            });
          });

          console.log(this.faculties);
        }
      } catch (error) {
        console.log(error);
      }
                },
                 async fetchDepartments(){
                    try {
                        this.department = '';
                        const res = await axios.get("http://127.0.0.1:5000/getalldepartments");
            // console.log('Department', res);
                        if (res.data.code === '00') {
                        const departments = res.data.data.map(el => {
                                return {
                                    text: el.name,
                                    value: el._id
                                }
                            })
        
                            this.departments.push(...departments)
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
                 async fetchCourses() {
                    try {
                        const res = await axios.get('http://localhost:5000/get-courses');
                        if (res.data.code == 's200') {
                            res.data.message.forEach(course => {
                                this.courses.push({
                                    text: course.name,
                                    value: {
                                        id: course._id,
                                        name: course.name
                                    }
                                })
                            })
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },
            }
        
        }
        </script>
    
    <style scoped>
    /* .select {
        width: 100%;
        height: 56px;
        border: 1px solid #E8E9EC;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        border-radius: 4px;
    } */
    
    .filter {
        padding: 10px 20px;
        width: 100%;
        background: #fff;
        border: 1px solid rgb(215, 211, 211);
    }
    
    .menu_text {
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
    
        color: #4F4F4F;
    }
    
    .next_button {
        padding: 15px;
        width: 100%;
        height: 46px;
        background: #006794;
        border-radius: 3px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
    }
    
    .decline {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        width: 40%;
        height: 40px;
        background: rgb(241, 83, 83);
        border-radius: 3px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
    }
    
    .approve {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        width: 40%;
        height: 40px;
        background: #006794;
        border-radius: 3px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
    }
    
    .reset {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px;
        width: 40%;
        height: 40px;
        background: #7fea98;
        border-radius: 3px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
    }
    
    .btnbtn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    </style>
    