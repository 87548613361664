<template>
    <div class="home">
        <div class="row">
            <div class="col-md-12"
                style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
                <span style="color:#777; font-size:18px;padding:5px;">General Studies</span>/<span
                    style="padding:5px; color:#555">ID card generation</span>
            </div>
        </div>

        <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
            <v-card class="pt-2" elevation:10>

                <v-row class="m-3">
                    <v-col class="col-sm-12 col-md-6 col-lg-2">

                        <v-text-field dense clearable outlined v-model="regnumber"
                            label="Enter Reg Number"></v-text-field>
                    </v-col>
                    <v-col class="col-sm-12 col-md-6 col-lg-2">
                        <v-autocomplete dense clearable outlined v-model="session" :items="sessions"
                            label="Select Session"></v-autocomplete>
                    </v-col>
                    <v-col class="col-sm-12 col-md-6 col-lg-2">

                        <v-autocomplete dense clearable outlined v-model="department" :items="departments"
                            label="Select Department"></v-autocomplete>
                    </v-col>
                    <v-col class="col-sm-12 col-md-6 col-lg-2">
                        <v-flex xs12 sm12 md12>
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="date" lazy transition="scale-transition" offset-y full-width
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="date" label="From date" outlined dense readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="menu = false">Cancel</v-btn>
                                    <v-btn color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-col>

                    <v-col class="col-sm-12 col-md-6 col-lg-2">
                        <v-flex xs12 sm12 md12>
                            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="date1" lazy transition="scale-transition" offset-y full-width
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field outlined dense v-model="date1" label="To date" readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date1" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="menu2 = false">Cancel</v-btn>
                                    <v-btn color="primary" @click="$refs.menu2.save(date1)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-col>

                    <v-col class="col-sm-12 col-md-6 col-lg-2" style="margin-top:0 !mportant">
                        <v-btn color="#006794" style="color: #fff" @click="submit" v-if="!activities" class="col-md-8">
                            Search </v-btn>
                        <v-btn color="#c0e7fe" class="col-md-8" style="margin:auto" disabled v-else>
                            <v-progress-circular indeterminate color="#777" :size="25"></v-progress-circular> &nbsp;
                            Searching
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card>

            <v-row style="margin:0 !important; padding:0 !important">
                <v-col cols="12" style="">
                    <div class="row">
                        <div class="col-md-8 "> </div>
                        <div class="col-md-4 mb-8">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Table" single-line
                                hide-details></v-text-field>
                        </div>
                    </div>
                    <v-data-table :headers="headers" :items="data" :page.sync="page" :items-per-page="itemsPerPage"
                        class="elevation-1" @page-count="pageCount = $event" :search="search" show-select>

                        <template v-slot:[`item.sn`]="{ item }">
                            <tr>
                                <td class="table-txt">{{ data.map(function (x) { return x.reg; }).indexOf(item.reg) + 1
                                    }}
                                </td>
                            </tr>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="" text v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="reset(item.el)">
                                        <v-list-item-title>
                                            <v-icon small class="mr-2">
                                                mdi-clipboard-arrow-print
                                            </v-icon>Print ID card
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>

                        <!-- <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>

        </div>
      </template>
       -->

                    </v-data-table>

                    <div class="text-center pt-2">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        <v-spacer></v-spacer>
                        <!-- <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field> -->
                    </div>

                </v-col>
            </v-row>
            <div>
            </div>
        </div>

        <!-- Modals -->
        <v-dialog v-model="dialogRegset" width="600">
            <v-card>
                <v-card-title style="color:#666; font-size:16px">
                    GS ID card
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogRegset = false">mdi-close-circle</v-icon>

                </v-card-title>
                <v-divider style="margin-top:0px"></v-divider>

                <v-card-text>
                    <v-row>

                        <div ctr>
                            <div NodeToImage>


                            </div>

                        </div>





                        <div class="card" ref="content" id="content">
                            <div class="col-md-12 pa-0 ma-0 footer" >
                                <div class="row" style="margin:0; padding:0;">
                                    <div class="col-md-2">
                                        <img class="img-header" src="../assets/unizik_logo.png" alt=""
                                            style="margin-top:-11px; margin-left:-15px" />
                                    </div>
                                    <div style="text-align: center; font-size: 8px; font-weight: 600; color: #000 !important;"
                                        class="col-md-8">
                                        <div style="margin-top:-10px">SCHOOL OF GENERAL STUDIES</div>
                                        <div style="margin-top:-10px">NNAMDI AZIKIWE UNIVERSITY, AWKA</div>
                                        <!-- <div style="margin-top:-10px">AWKA</div> -->
                                    </div>
                                    <div class="col-md-2">
                                        <img class="img-header " src="../assets/unizik_sgs_logo.jpeg" alt=""
                                            style="margin-top:-11px; margin-left:-8px" />
                                    </div>
                                </div>
                                <div class="divider flex space-between" style="margin-top:-35px">
                                    <!-- <span class="dot"></span>
                                    <span class="dot"></span> -->
                                </div>
                                <div class="row text-center mt-5">
                                    <div class="col-md-4" style="text-align: center !important;">
                                        <div style="margin-top:-15px;margin-left:10px;">
                                            <v-img class=" " v-bind:src="courseInfo.studentId.profileUrl" alt=""
                                                width="60" style=" border: solid 1px orange; border-radius: 8px;" />
                                        </div>
                                        <div style="font-size: 8px; color: #000 !important; font-weight: bold; text-transform: capitalize; width: 100%;"
                                            class="text-center no-margin">
                                            <div style="margin-top:-4px;" v-if="courseInfo.studentId.surname">
                                                {{ courseInfo.studentId.surname.toUpperCase() }}</div>
                                            <div style="margin-top:-14px" v-if="courseInfo.studentId.firstname">
                                                {{ courseInfo.studentId.firstname.toUpperCase() }}</div>
                                            <div style="margin-top:-14px" v-if="courseInfo.studentId.othernames">
                                                {{ courseInfo.studentId.othernames.toUpperCase() }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-8"
                                        style="margin-top:-30px; margin-left:-10px; text-align: left;">
                                        <div style="font-size: 7px; color: black !important; ">
                                            <div style="width:100%;font-weight: bold !important;font-size: 6px;">
                                                FACULTY: </div>
                                            <div style="margin-top:-10px; font-size: 12px;"
                                                v-if="courseInfo.studentId.faculty">{{
                                                courseInfo.studentId.faculty.name.toUpperCase() }}</div>
                                        </div>
                                        <div style="font-size: 7px; color: black !important; margin-top:-7px">
                                            <div style="width:100%;font-weight: bold !important;font-size: 6px;">
                                                DEPARTMENT:</div>
                                            <div style="margin-top:-10px; font-size: 12px;"
                                                v-if="courseInfo.studentId.department">{{
                                                courseInfo.studentId.department.name.toUpperCase() }}</div>
                                        </div>

                                        <div style="font-size: 7px; color: black !important;   margin-top:-7px">
                                            <div style="width:100%;font-weight: bold !important;font-size: 6px;">
                                                SEX:</div>
                                            <div style="margin-top:-10px; width:200px; font-size: 12px;"
                                                v-if="courseInfo.studentId.gender">
                                                {{ courseInfo.studentId.gender }}</div>

                                        </div>

                                        <div class="row">
                                            <div style="font-size: 7px; color: black !important; margin-top:-7px"
                                                class="col-md-6">
                                                <div style="width:100%;font-weight: bold !important;font-size: 6px;">
                                                    PROGRAME:</div>
                                                <div style="margin-top:-10px; font-size: 12px;"
                                                    v-if="courseInfo.studentId.program == '6176addadfe4749cf0a90957'">
                                                    REGULAR</div>
                                                <div style="margin-top:-10px; font-size: 12px;"
                                                    v-if="courseInfo.studentId.program == '62d6e91e98f3bc20610b999f'">
                                                    CEP</div>
                                                <div style="margin-top:-10px; font-size: 12px;"
                                                    v-if="courseInfo.studentId.program == '63ce965628041bb4f940b2b9'">
                                                    SANDWICH</div>
                                            </div>
                                            <div style="font-size: 7px; color: black !important;  margin-top:-7px"
                                                class="col-md-6">
                                                <div style="width:100%;font-weight: bold !important;font-size: 6px;">REG
                                                    NO:</div>
                                                <div style="margin-top:-10px; font-size: 12px;"
                                                    v-if="courseInfo.studentId.regNumber">{{
                                                    courseInfo.studentId.regNumber.toUpperCase() }}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="line line-1" style="margin-left:-10px"></div>
                                <div class="line line-2" style="margin-left:-10px"></div>
                            </div>



                        </div>

                        <div style="">
                            <button style="color: #094e2f; background-color: #fff; padding: 10px 20px"
                                @click="downloader">
                                Download ID Card
                            </button>
                            <!-- <button style="color: #094e2f; background-color: #fff; padding: 10px 20px" @click="downloadFront">
                                Download Back
                            </button> -->
                        </div>
                        <div class="card" ref="content1">
                            <h2 class="headWord text-center no-margin">
                                This remains the property of School of General <br />
                                Studies, Nnamdi Azikiwe University, Awka.
                            </h2>

                            <div style="text-align: center;margin-top:20;">
                                <div style="height:5px"></div>
                                <qr-code :text="verifyUrl" size="100" style="margin-left:32%;"></qr-code>
                                <div style="height:5px"></div>
                            </div>

                            <div style="font-size: 14px; font-weight: bold; color: black !important;"
                                class="text-center label">
                                Valid through December 2025
                            </div>

                            <div class="liney-wrap">
                                <div class="liney-1 liney"></div>
                                <div class="liney-2 liney"></div>
                            </div>
                        </div>

                    </v-row>
                    <!-- <v-card-actions class="m-auto">
                    <v-btn color="#c0e7fe" @click="resetCourse" class="col-md-5 ma-auto">Proceed</v-btn>
                </v-card-actions> -->
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import jsPDF from 'jspdf'
import domtoimage from "dom-to-image";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    components: {

    },
    data() {
        return {
            verifyUrl: '',
            dataUrl: 'https://example.com',
            img1: '',
            img2: '',
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            date1: new Date().toISOString().substr(0, 10),
            search: '',
            dialogCourse: false,
            show: false,
            page: 1,
            itemsPerPage: 100,

            levels: [{
                text: '100',
                value: 1
            }, {
                text: '200',
                value: 2
            }, {
                text: '300',
                value: 3
            }, {
                text: '400',
                value: 4
            }, {
                text: '500',
                value: 5
            }, {
                text: '600',
                value: 6
            }],
            dialogRegset: false,
            courses: [],
            sessions: [{
                text: 2023,
                value: 2023
            }],
            faculties: [],
            departments: [],
            regnumber: '',
            program: '',
            programs: [],
            session: '',
            level: '',
            department: '',
            faculty: '',
            course: '',
            data: [],
            activities: false,
            courseInfo: {
                studentId: {},
                facultyId: {},
                departmentId: {},
                program: {}
            },
            headers: [{
                text: 'S/N',
                value: 'sn'
            }, {
                text: 'Reg Number',
                value: 'reg'
            },
            {
                text: 'Fullname',
                value: 'fullname'
            },
            // {
            //   text: 'Program',
            //   value: 'program'
            // },
            // {
            //   text: 'Department',
            //   value: 'department'
            // },
            // {
            //   text: 'Session',
            //   value: 'session',
            // },
            {
                text: 'Level',
                value: 'level'
            },
            {
                text: 'Date Registered',
                value: 'date',
            },
            {
                text: 'Actions',
                value: 'actions'
            }

            ]
        }
    },
    beforeMount() {
        this.fetchFaculties();
        this.fetchDepartments();
        this.fetchCourses();
        // this.fetchProgram();
        this.fetchSession();
    },

    computed: {
        pageCount() {
            return Math.ceil(this.data.length / this.itemsPerPage)
        },
    },

    mounted() {
        this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;

    },
    methods: {
        reset(item) {
            this.courseInfo = item;
            this.dialogRegset = true
            this.verifyUrl = `https://stu.unizik.edu.ng/#/verifyGs/${item.studentId.regNumber}`
        },


        async downloader(){
            var element = document.getElementById("content");
  var document_name = 'kkkkkkk';
  var opt = {
    margin: [0, -0.1, 0, 0],
    filename: document_name + ".pdf",
    image: { type: "jpeg", quality: 1 },
    // Added after option to add spacing after page break
    pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1", after: "1cm" },
    html2canvas: { scale: 4, useCORS: true, dpi: 192, letterRendering: true },
    // Added putTotalPages option to add page number
    jsPDF: { unit: "in", format: "a4", orientation: "portrait", putTotalPages: true },
  };

  html2pdf().set(opt).from(element).save();
        }
        ,
        async downloadFront() {
            /** WITH CSS */
            var t = this
            var scale = 2;
            var NodeToImage = document.querySelector('[NodeToImage]');

            await domtoimage
                .toPng(this.$refs.content, {
                    width: this.$refs.content.clientWidth * 2,
                    height: this.$refs.content.clientHeight * 2,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left',
                    }
                }).then(function (dataUrl) {
                    var img = new Image();
                    img.src = dataUrl;
                    t.img1 = img.src;
                });

            await domtoimage
                .toPng(this.$refs.content1, {
                    width: this.$refs.content1.clientWidth * 2,
                    height: this.$refs.content1.clientHeight * 2,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left'
                    }
                }).then(function (dataUrl) {
                    var img1 = new Image();
                    img1.src = dataUrl;
                    t.img2 = img1.src;
                   
                });
            console.log(NodeToImage.clientHeight)
            const doc = new jsPDF({
                orientation: "landscape",
                unit: "px",
                format: [NodeToImage.clientHeight * 2, NodeToImage.clientWidth * 2]
            });
            doc.addImage(t.img1, "PNG", 1, 1);
            doc.addPage();
            doc.addImage(t.img2, "PNG", 1, 1);
            const filename = `${this.courseInfo.studentId.regNumber}_idcard.pdf`
            doc.save(filename);
        },

        openRegisteredCourses(item) {
            console.log(item);
            this.courseInfo = item;
            this.dialogCourse = true;
        },


        close() {
            this.course = '';
            this.regnumber = '',
                this.department = '';
            this.faculty = '';
            this.show = false;
            this.data = [];
            this.session = '';
            this.level = '';
        },

        async submit() {
            this.activities = true
            this.data = []
            if (!this.session) {
                this.activities = false

                return Swal.fire({
                    icon: 'info',
                    text: 'Please select session',
                    title: 'Oops..'
                })
            }



            try {
                const data = {
                    courseId: this.course ? this.course.id : 'undefined',
                    departmentId: this.department ? this.department : 'undefined',
                    facultyId: this.faculty.id ? this.faculty.id : 'undefined',
                    level: this.level ? this.level : 'undefined',
                    session: this.session ? this.session : 'undefined',
                    program: '6176addadfe4749cf0a90957',
                    regNumber: this.regnumber ? this.regnumber : 'undefined',
                    datefrom: this.date,
                    dateTo: this.date1
                };
                const url = 'https://appcentral.unizik.edu.ng/v1/admin/fetch-paid-gs-for-card'
                const res = await axios.post(url, data);
                console.log(res);

                if (res.data.code == 's200') {
                    this.activities = false
                    const data = []
                    if (res.data.message.length <= 0) {
                        return Swal.fire({
                            icon: 'info',
                            text: 'No record found for the filters selected',
                            title: 'Oops..'
                        })
                    }
                    console.log(res.data.message);
                    res.data.message.forEach(el => {
                        data.push({
                            reg: el.level == '1' ? el.studentId.admissionNumber : el.studentId.regNumber,
                            date: el.dateRegistered,
                            fullname: `${el.studentId.surname} ${el.studentId.firstname}`,
                            // program: el.program.program,
                            // faculty: el.facultyId.name,
                            department: el.departmentId.name,
                            // session: el.session,
                            level: el.level + '00',
                            semester: el.semester,
                            id: el.studentId._id,
                            el,
                            data: JSON.stringify(el)
                        })
                    });

                    this.data = data;
                    this.show = true;
                    this.activities = false

                }
            } catch (error) {
                console.log(error);
            }
        },

    }

}
</script>

<style scoped>
body {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    color: black;
}

.text-headerx {
    font-size: 11px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: whitesmoke;
    height: 100vh;
    width: 100%;
}

.headWord {
    font-size: 8px;
    color: #000;
    font-weight: bold
}

.card {
    height: 185.24px;
    width: 285.24px;
    border-radius: 10px;
    background-color: white;
    padding: 5px;
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.img-header {
    height: 40px;
    width: 40px;
}

.divider {
    border-bottom: 0px  #22608c;
    width: 40%;
    margin: 0.2rem auto;
}

.no-margin {
    margin: 0;
}

.dot {
    height: 3px;
    width: 3px;
    background-color: white;
    /* border: 1px solid #22608c; */
    display: block;
    margin-bottom: -2px;
    /* transform: rotate(20deg); */
}

.profile-img {
    height: 70px;
    width: 70px;
    border: 2px solid #d25401;
    border-radius: 10px;
    margin-bottom: 0.1rem;
}

.labelxx {
    font-weight: bold;
    margin-bottom: 1.5px;
    display: block;
}

.infoxx {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

.line {
    padding: 2px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.line-1 {
    width: 20%;
    background-color: #22608c;
}

.line-2 {
    width: 40%;
    background-color: #d25401;
}

.liney-wrap {
    width: 40%;
    ;
    transform: rotate(40deg);
    margin-left: -40px;
    margin-top: -10px;
}

.liney {
    padding: 5px;
}

.liney-1 {
    background-color: #22608c;
    margin-bottom: .5rem;
}

.liney-2 {
    background-color: #d25401;
}

[ctr] {
    width: 162.24px;
    height: 105.24px;
}


/* This is the DOM element to be converted */
[NodeToImage] {
    height: 100%;
    /* To match the height */
}
@media print {
  footer {page-break-after: always;}
}
</style>
