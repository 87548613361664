<template>
<div class="home">
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">General Studies</span>/<span style="padding:5px; color:#555">Issue Book</span>
    </div>
  </div>

  <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
    <v-card class="pa-5">
      <v-row>
        <v-col class="col-lg-3 col-md-6 col-sm-12 py-0">

          <v-text-field dense clearable outlined v-model="regnumber" label="Enter Reg Number"></v-text-field>
        </v-col>

        <v-col class="col-lg-3 col-md-6 col-sm-12 py-0">
          <v-autocomplete dense clearable outlined v-model="session" :items="sessions" label="Select Session"></v-autocomplete>
        </v-col>
        <v-col class="col-lg-3 col-md-6 col-sm-12 py-0">

          <v-autocomplete dense clearable outlined v-model="course" :items="courses" label="Select Course"></v-autocomplete>
        </v-col>
        <v-col class="col-lg-3 col-md-6 col-sm-12 py-0">

          <v-autocomplete dense clearable outlined v-model="faculty" :items="faculties" label="Select Faculty"></v-autocomplete>
        </v-col>

        <v-col class="col-lg-3 col-md-6 col-sm-12 py-0">

          <v-autocomplete dense clearable outlined v-model="department" :items="departments" label="Select Department"></v-autocomplete>
        </v-col>

        <v-col class="col-lg-3 col-md-6 col-sm-12 py-0">

          <v-autocomplete dense clearable outlined v-model="level" :items="levels" label="Select Level"></v-autocomplete>
        </v-col>

        <v-col class="col-sm-12 col-md-6 col-lg-4">
          <strong class="mb-10 d-block"></strong>
          <div>
            <v-btn color="" @click="close"> Clear </v-btn>
            <span style="margin: 0 .3rem"></span>
            <v-btn color="#006794" style="color: #fff" @click="submit" v-if="!activities"> Search </v-btn>
            <v-btn color="#c0e7fe" @click="save"  class="col-md-5" style="margin:auto" disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Searching</v-btn>
          </div>
        </v-col>

      </v-row>

      <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
          <div class="row">
            <div class="col-md-8 "> </div>
            <div class="col-md-4 mb-8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Table" single-line hide-details></v-text-field>
            </div>
          </div>
          <v-data-table :items="data" :search="search" :headers="headers">
            <template v-slot:item.actions="{item}">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="openRegisteredCourses(item.el)">
                    <v-list-item-title>
                      <!-- <img style="height: 20px; width: 20px" src="../../../assets/icons8-external-link-48.png" alt=""> -->
                      Assign Book</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div>
      </div>
    </v-card>
  </div>

  <!-- Modals -->

  <v-dialog v-model="dialogCourse" max-width="650">
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
        Registered Course
        <v-spacer></v-spacer>
        <v-icon @click="dialogCourse=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>
        <v-row>

          <div class="col-md-12 pa-7 pb-0">
            <h6>Bio Information</h6>
            <div class=" d-flex justify-start align-center">
              <img style="height: 150px; overflow: hidden; width: 150px; border-radius: 50%; border: 1px solid gray" :src="courseInfo?.studentId?.profileUrl" alt="">
              <div class="ml-7">
                <p><strong>Surname: </strong>{{courseInfo.studentId.surname}}</p>
                <p><strong>First Name: </strong>{{courseInfo.studentId.firstname}}</p>
                <p><strong>Othernames: </strong>{{courseInfo.studentId.othernames}}</p>
                <p><strong>Program: </strong>{{courseInfo.program.program}}</p>
                <p><strong>Faculty: </strong>{{courseInfo.facultyId.name}}</p>
                <p><strong>Department: </strong>{{courseInfo.departmentId.name}}</p>
              </div>

            </div>
          </div>

          <!-- <div class="col-md-12">
              <v-divider style="margin-top:0px"></v-divider>
            </div> -->

          <div class="col-md-12 pa-5">
            <v-row>

              <v-col cols="6" class="py-0">
                <h6>Registered Courses</h6>
              </v-col>
              <v-col cols="6" class="py-0">
                <h6>Books</h6>
              </v-col>
            </v-row>
            <v-divider style="margin-top:10px" class="mb-4"></v-divider>
            <div class="col-md-12 pa-0" style="height: 350px; overflow-y: scroll">
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in booksCourse" :key="i">
                    <v-list-item-icon>
                      <v-icon>mdi-decagram-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="4" class="">
                          <v-list-item-title v-text="item.course.name"></v-list-item-title>
                          <span>{{item.course.code}}</span>
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <v-checkbox class="pa-0" v-for="(book, j) in item.books" :key="j" v-model="selectedBooks" :label="book.name" :disabled="book.disabled" :value="book._id"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </div>

        </v-row>
        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn color="#c0e7fe" @click="assignBook" class="col-md-5 ma-auto">Assign Books</v-btn>
        </v-card-actions>
      </v-card-text>

    </v-card>
  </v-dialog>
</div>
</template>

  
  
<script>
import {allFaculties,allSession,allDepartments} from '@Appmart/utility'

import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      activities:false,
      search: '',
      selectedBooks:[],
      booksCourse: [],
      dialogCourse: false,
      show: false,
      levels: [{
        text: '100',
        value: 1
      }, {
        text: '200',
        value: 2
      }, {
        text: '300',
        value: 3
      }, {
        text: '400',
        value: 4
      }, {
        text: '500',
        value: 5
      }, {
        text: '600',
        value: 6
      }],
      dialogRegset: false,
      courses: [],
      sessions: [ {text: 2021,
        value: 2020}],
      faculties: [],
      departments: [],
      regnumber: '',
      program: '',
      programs: [],
      session: '',
      level: '',
      department: '',
      faculty: '',
      course: '',
      data: [],
      courseInfo: {
        studentId: {},
        facultyId: {},
        departmentId: {},
        program: {}
      },
      headers: [{
          text: 'Reg Number',
          value: 'reg'
        },
        {
          text: 'Fullname',
          value: 'fullname'
        },
        {
          text: 'Program',
          value: 'program'
        },
        {
          text: 'Faculty',
          value: 'faculty',
        },
        {
          text: 'Department',
          value: 'department'
        },
        // {
        //   text: 'Session',
        //   value: 'session',
        // },
        {
          text: 'Level',
          value: 'level'
        },
        {
          text: 'Actions',
          value: 'actions'
        }

      ],
      books: []
    }
  },
  beforeMount() {
  
    this.fetchFaculties();
    this.fetchDepartments();
    this.fetchCourses();
    // this.fetchProgram();
    this.fetchSession();
    this.getBooks();
    
  },
  mounted(){
    this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;

  },
  methods: {
    async assignBook() {
      try {
        const url = this.$appUrl+'/assign-book';
        const res = await axios.post(url, {regNumber: this.courseInfo.studentId.regNumber,
bookIds: this.selectedBooks})

// console.log(res);
if(res.data.code == 's200'){
  this.dialogCourse = false;
Swal.fire({icon: 'success', text: `Book was successfully assigned to ${this.courseInfo.studentId.surname} ${this.courseInfo.studentId.firstname}`});

}else{
  Swal.fire({icon: 'error', text: res.data.message, title: 'Oops..'})
}
      } catch (error) {
        console.log(error);
      }
    },
    async getBooks() {
      try {
        const url = this.$appUrl+'/get-books?gsBooks=1'
        const res = await axios.get(url);

        // console.log('Books', res);
        if (res.data.code == 's200') {
          // console.log('Courses');
          this.books = res.data.message;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openRegisteredCourses(item) {
      // console.log(item);
      this.selectedBooks = []
      this.courseInfo = item;
      this.fetchAssignedBooks();
      this.booksCourse = item.courses.map(el => {
        const books = this.books.filter(book =>{
          if( book.courseCode == el.course_code){
            return{
              ...book,
              disabled: false
            }
          }
        });
        return {
          course: {
            name: el.name,
            code: el.course_code
          },
          books,
        }
      })

      console.log(this.booksCourse)
      this.dialogCourse = true;
    },

    async fetchAssignedBooks(){
      try {
        // console.log(this.courseInfo);
        const  url = this.$appUrl+'/fetch-assigned-books';
        const res = await axios.post(url, {studentId: this.courseInfo.studentId._id});
        // console.log('Assigned Book',res);
        if(res.data.code == 's200'){
          const books = {};
          res.data.message.bookIds.forEach((el,i) => {
            books[el._id] = i;
            this.selectedBooks.push(el._id);
          });

          console.log('Books',books);

         this.booksCourse.forEach(el => {
          el.books.forEach(book => {
            // eslint-disable-next-line no-prototype-builtins
            if(books.hasOwnProperty(book._id)){
            book.disabled = true;
            }
          })
         })
        }
      } catch (error) {
        console.log(error)
      }
    },  

    reset(item) {
      this.courseInfo = item;
      this.dialogRegset = true;
      console.log(item);
    },
    close() {
      this.program = '';
      this.course = '';
      this.regnumber = '',
        this.department = '';
      this.faculty = '';
      this.show = false;
      this.data = [];
      this.session = '';
      this.level = '';
    },

    async submit() {
      this.activities = true
      if (!this.session) {
        this.activities= false
        return Swal.fire({
          icon: 'info',
          text: 'Please selects session',
          title: 'Oops..'
        })
      }
      try {
        const data = {
          courseId: this.course ? this.course.id : 'undefined',
          departmentId: this.department ? this.department : 'undefined',
          facultyId: this.faculty ? this.faculty : 'undefined',
          level: this.level ? this.level : 'undefined',
          session: this.session ? this.session : 'undefined',
          program: this.program,
          // regNumber: this.regnumber ? this.regnumber : 'undefined'
        };

       if(this.regnumber){
        if(!isNaN(this.regnumber)){
          data['regNumber'] = this.regnumber;
          console.log('regnum');
        }else{
          data['admissionNumber'] = this.regnumber;
          console.log('addnum');
        }
       }
       const url = this.$appUrl+'/fetch-registered-gs-courses'
        const res = await axios.post(url, data);
        console.log(res);

        if (res.data.code == 's200') {
          const data = []
if(res.data.message.length <= 0){
  this.activities= false
        return Swal.fire({
          icon: 'info',
          text: 'No record found for the filters selected',
          title: 'Oops..'
        })
}

          res.data.message.forEach(el => {
            data.push({
              reg: el.studentId.level == 1 ? el.studentId.admissionNumber : el.studentId.regNumber,
              fullname: `${el.studentId.surname} ${el.studentId.firstname}`,
              program: el.program.program,
              faculty: el.facultyId.name,
              department: el.departmentId.name,
              // session: el.session,
              level: el.level + '00',
              semester: el.semester,
              id: el.studentId._id,
              el,
              data: JSON.stringify(el)
            })
          });

          this.data = data;
          this.show = true;

        }
this.activities = false;
      } catch (error) {
        this.activities=false
        console.log(error);
      }
    },
    async fetchProgram() {
      try {
        const url = this.$appUrl+'/fetchprograms'
        const res = await axios.get(url);
        // console.log('RESET', res)
        if (res.data.code == '00') {
          res.data.data.forEach(program => {
            this.programs.push({
              text: program.program,
              value: program.slug
            })
          });
        } else {
          console.log("ERROR")
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchSession() {
      try {
        
        const res = await allSession().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
        if (res.data.code === 's200') {
          res.data.message.forEach(session => {
            this.sessions.push({
              value: session.academicYear,
              text: session.academicYear,
            })
          })

        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFaculties() {
      try {
        const res = await allFaculties().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
if (res.data.code == '00') {

this.faculties = res.data.message.map((el) => {
  return {
    text: el.name,
    value: {
      name: el.name,
      id: el._id
    }
  }
});
// console.log(datas);

}
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDepartments() {
      try {
        this.department = '';
        
        const res = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
        // console.log('Department', res);
        if (res.data.code === '00') {
          const departments = res.data.message.map(el => {
            return {
              text: el.name,
              value: el._id
            }
          })

          this.departments.push(...departments)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCourses() {
      try {
        const url = this.$appUrl+'/get-courses?isGSCourse=1'
        const res = await axios.get(url);
        if (res.data.code == 's200') {
          res.data.message.forEach(course => {
            this.courses.push({
              text: course.name,
              value: {
                id: course._id,
                name: course.name
              }
            })
          })
        }
      } catch (error) {
        console.log(error);
      }
    },
  }

}
</script>
  
  
<style>
  
  </style>
