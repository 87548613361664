<template>
  <v-app>

    <v-main style="background-color:#F5F5F5">

      <router-view/> 
    </v-main>
  </v-app>
</template>


<script>
import "bootstrap/dist/css/bootstrap.css"
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
